import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SendbirdCustomService } from 'src/app/service/sendbird-custom.service';

@Injectable({
  providedIn: 'root'
})
export class ColabRedirectService {
  members: any;	
  userRole: any;
	
  constructor(
  private router: Router,
  private sbservice: SendbirdCustomService,
  ) {
	this.createSendbirdConnection();
	this.userRole = localStorage.getItem('user-role');
  }
  
  async userRedirect(user: any) {
    const nickname = user.fname + ' ' + user.lname;
    this.members = JSON.parse(localStorage.getItem('memberList'));

    let memberListNew = localStorage.hasOwnProperty('memberListNew')
      ? JSON.parse(localStorage.getItem('memberListNew'))
      : [];
    //localStorage.setItem('current_selected_colleague', user.id);
    localStorage.removeItem('selectedTeam');
    localStorage.removeItem('selectedTeamName');
    localStorage.setItem('selectedTeamName', nickname);
    localStorage.setItem(
      'collogue_user_id',
      user.sendbird_user_details.user_id
    );
    localStorage.setItem('current_selected_colleague', user.id);
    localStorage.setItem('current_colleague_fname', user['fname']);
    localStorage.setItem('current_colleague_lname', user['lname']);

    let find_user_index = this.members.findIndex(
      (e) =>
        !e.sendbird_team_details &&
        e.userId == user.sendbird_user_details?.user_id &&
        e.isDistinct
    );

    let find_user = find_user_index >= 0 ? this.members[find_user_index] : null;
    if (!find_user) {
      localStorage.removeItem('channel_url');
      await this.createColleagueSendbird(user.sendbird_user_details?.user_id);
      this.members.splice(0, 0, user);
      this.members[0].userId = user.sendbird_user_details?.user_id;
      this.members[0].isDistinct = true;
      this.members[0].unreadMessageCount = 0;
      this.members[0].nickname = user.fname + ' ' + user.lname;
      this.members[0].colleagueId = user.id;

      // if(!this.members[0].sendbird_team_details)
      // delete this.members[0].id;
      //if(user.sendbird_user_details?.user_id != memberListNew[0].userId)
      memberListNew.splice(0, 0, this.members[0]);

      localStorage.setItem('memberList', JSON.stringify(this.members));
      localStorage.setItem('selectedTORC', JSON.stringify(this.members[0]));
      localStorage.setItem('memberListNew', JSON.stringify(memberListNew));
      find_user_index = 0;
    }

    localStorage.setItem(
      'memberListSelectionIndex',
      JSON.stringify(find_user_index)
    );
    this.router.navigate(['dashboard/members-collab']);
  }
  
  async teamRedirect(user: any) {
    this.members = JSON.parse(localStorage.getItem('memberList'));
    let memberListNew = localStorage.hasOwnProperty('memberListNew')
      ? JSON.parse(localStorage.getItem('memberListNew'))
      : [];
    localStorage.setItem('selectedTeamCreatedBy', user.createdby);
    localStorage.setItem('selectedTeamCreatedOn', user.createdon);
    localStorage.setItem('selectedTeamName', user.name);
    localStorage.setItem('selectedTeam', user.id);
    localStorage.setItem('selectedTeamDescription', user['desc']);
    localStorage.setItem('channel_url', user.sendbird_team_details.channel_url);
    let find_user_index = this.members.findIndex(
      (e) =>
        e.sendbird_team_details &&
        e.sendbird_team_details.channel_url ==
          user.sendbird_team_details.channel_url &&
        !e.isDistinct
    );

    let find_user = find_user_index >= 0 ? this.members[find_user_index] : null;
    if (!find_user) {
      this.members.splice(0, 0, user);
      //this.members[0].userId = user.sendbird_user_details?.user_id;
      this.members[0].isDistinct = user.sendbird_team_details ? false : true;
      this.members[0].unreadMessageCount = 0;

      // if(!this.members[0].sendbird_team_details)
      // delete this.members[0].id;
      memberListNew.splice(0, 0, this.members[0]);
      localStorage.setItem('selectedTORC', JSON.stringify(this.members[0]));
      localStorage.setItem('memberList', JSON.stringify(this.members));
      localStorage.setItem('memberListNew', JSON.stringify(memberListNew));
      find_user_index = 0;
    }

    localStorage.setItem(
      'memberListSelectionIndex',
      JSON.stringify(find_user_index)
    );
    this.router.navigate(['dashboard/members-collab']);
  }
  
  async createColleagueSendbird(collegueId) {
    if (!collegueId) return;
    await this.sbservice
      .sbCreateChannelWithUserIds([collegueId], true)
      .then((e: any) => {
        localStorage.setItem('channel_url', e.url);
      })
      .catch((error) => {
        console.log(
          'SendBird sbCreateChannelWithUserIds failed',
          this.constructor,
          error
        );
      });
  }
  
  createSendbirdConnection() {
    this.sbservice
      .sbConnect()
      .then(() => {})
      .catch((error) => {
        console.log('SendBird connection failed', this.constructor, error);
      });
  }
  
  globalGroupClick(event) {
    localStorage.removeItem('currentGlobalSearchKey');
    localStorage.removeItem('showMoreDataLoaded');
    //this.comm.connectSuperGroup$.next(event);
    if (event?.id) {
      this.selectTeamCirclenew(
        event?.id,
        event?.name,
        event?.desc,
        event?.sendbird_team_details['application_id'],
        event?.sendbird_team_details['channel_url'],
        event?.usercount,
        event?.createdby,
        event?.createdon,
        event?.is_colleague_visibility
      );
    }
  }
  
  selectTeamCirclenew(
    id: any,
    name: any,
    desc: any,
    app_id: any,
    ch: any,
    usercount: any,
    createdby: any,
    createdon: any,
    selectedColleagueVisibility: any
  ) {
    const teamid = localStorage.getItem('selectedTeam');
    //this.eventEmitterService.removeHandlerEmitter.emit(true);
    if (teamid != id) {
      localStorage.setItem('maxFileSelection', '0');
      localStorage.setItem('maxFileSelectionIncremental', '0');
      localStorage.removeItem('selected-files');
      localStorage.removeItem('selected-links');
      localStorage.removeItem('selected-images');

      localStorage.removeItem('currentImages');
      localStorage.removeItem('currentFiles');
      localStorage.removeItem('currentLinks');
    }
    localStorage.removeItem('collab-chat');
    localStorage.removeItem('current_colleague_fname');
    localStorage.removeItem('current_colleague_lname');
    localStorage.removeItem('selectedColleague');

    //this.getMembers(id); // Populating the Users in the Mention Suggestions when Team is Selected.
    localStorage.setItem('selectedTeam', id);
    localStorage.setItem('selectedTeamName', name);
    localStorage.setItem('selectedTeamDescription', desc);
    localStorage.setItem(
      'selectedColleagueVisibility',
      selectedColleagueVisibility
    );
    localStorage.setItem('selectedTeamUsers', usercount);
    localStorage.setItem('selectedTeamCreatedOn', createdon);

    if (this.userRole == 'SUPERADMIN') {
      localStorage.setItem('selectedTeamCreatedBy', localStorage.getItem('id'));
    } else {
      localStorage.setItem('selectedTeamCreatedBy', createdby);
    }

    //localStorage.setItem('application_id', app_id);
    localStorage.setItem('channel_url', ch);
    localStorage.setItem('cropped-profile-image', null);
    localStorage.setItem('collogue_selected', 'false');
    localStorage.setItem('colab_isDistinct', 'false');
    localStorage.setItem('teamCollab', 'true');
    // this.eventEmitterService.colleagueSnap.emit(true);
    // this.eventEmitterService.collabTypeTeamEventEmitter.emit(true);
    // this.eventEmitterService.teamToColleagueEventEmitter.emit(true); //NR-3467
    // this.eventEmitterService.internalToTeamCard.emit(true);

    //this.eventEmitterService.emitForceUpdate();
    this.router.navigate(['dashboard/team-collab']);
  }
}
