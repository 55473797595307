import { Component, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateStringNotification'
})
export class TruncateStringNotificationPipe implements PipeTransform {

  transform(value, length:number=200) {
	value = value?.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ').trim();

	if(value == 'undefined' || value == null)
	return null;
	
	return value.length>length?value.substr(0, length) + '...':value;
	}

}
