import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-calling-sound',
  templateUrl: './calling-sound.component.html',
  styleUrls: ['./calling-sound.component.scss'],
})
export class CallingSoundComponent implements OnInit {
  @Input() isPlay;
  timeOut: any;
  audio: HTMLAudioElement;
  constructor() {
    this.audio = new Audio('assets/audio/call.mp3');
  }
  ngOnChanges() {
    if (this.isPlay == true) {
      this.audio.loop = true;
      this.audio.play();
      this.timeOut = setTimeout(() => {
        this.audio.pause();
        this.audio.currentTime = 0;
      }, 30000);
    } else {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }
  ngOnInit(): void {}
  ngOnDestroy() {
    clearInterval(this.timeOut);
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
