import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ValidateService } from 'src/app/service/validate.service';
import { Modal } from 'bootstrap';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StoreService } from 'src/app/service/store.service';
import { CommunicationService } from 'src/app/service/communication.service';

@Component({
  selector: 'app-otp-to-mobile',
  templateUrl: './otp-to-mobile.component.html',
  styleUrls: ['./otp-to-mobile.component.scss'],
})
export class OtpToMobileComponent implements OnInit {
  getOtpForm: FormGroup;
  btnActive: boolean = false;
  serverErrorMessage: string = '';
  constructor(
    private customValidator: ValidateService,
    private eventEmitterService: EventEmitterService,
    private httpService: AuthHttpService,
    private router: Router,
    private store: StoreService,
	private comm: CommunicationService,
  ) {
    this.getOtpForm = new FormGroup({
      mobileNo: new FormControl(null, [
        Validators.required,
        this.customValidator.mobileValidator(),
        Validators.maxLength(13),
        Validators.minLength(10),
      ]),
    });
  }

  ngOnInit(): void {}
  get otpFormControl() {
    return this.getOtpForm.controls;
  }
  onSubmit() {
    if (this.getOtpForm.invalid) {
      this.otpFormControl.mobileNo.markAsDirty();
      return;
    }
    let data: any = {
      email: this.store.getEmailFromLocal(),
      workphone: this.getOtpForm.value.mobileNo,
    };

    this.httpService.resendOtp(data).subscribe(
      (response) => {
        if (response.success) {
          //this.isSubmitted = true;
          this.getOtpForm.reset();
		  this.comm.clearOtp$.next('clearotp');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          this.serverErrorMessage = response.message;
        } else {
          this.serverErrorMessage = response.message;
		  this.comm.clearOtp$.next('clearotp');
        }
      },
      (_) => {
        this.serverErrorMessage = environment.errorMessage;
      }
    );
  }
  myFunc(val: any) {
    if (this.isNumeric(val.target.value)) {
      if (val.target.value.length >= 10) {
        this.btnActive = true;
      } else {
        this.btnActive = false;
      }
    } else {
      this.btnActive = false;
    }
  }
  isNumeric(s: number) {
    if (typeof s != 'string') {
      return false;
    }
    //We return false if the string is ""
    return !isNaN(s) && !isNaN(parseFloat(s));
  }
}
