import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthHttpService } from './auth-http.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private extArray = ['.dng', '.tif', '.tiff', '.DNG'];
  public src$: Observable<SafeUrl>;
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private authHttp: AuthHttpService
  ) {}

  shortName(fname: string, lname: string) {
    let shortName =
      fname.slice(0, 1).toUpperCase() + (lname != null)
        ? lname.slice(0, 1).toUpperCase()
        : null;
    return shortName;
  }

  stringWithCharLimit(fname: string, lname: string, limit: number = 0) {
    let fullname =
      fname.charAt(0).toUpperCase() +
      fname.slice(1) +
      ' ' +
      lname.charAt(0).toUpperCase() +
      lname.slice(1);

    let screenName =
      fullname.length > limit ? fullname.substr(0, limit) + '...' : fullname;
    return screenName;
  }

  capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  removeExtension(string1: any) {
    return string1.slice(0);
  }

  excerpt(str: string) {
    let len = str?.length;
    let subString: string = '';
    if (len > 10) {
      subString = str.substring(0, 10);
      subString = subString + ' ...';
    } else {
      subString = str;
    }
    return subString;
  }

  excerptDynmic(str: string, length: number) {
    let len = str?.length;
    let subString: string = '';
    if (len > length) {
      subString = str.substring(0, length);
      subString = subString + ' ...';
    } else {
      subString = str;
    }
    return subString;
  }

  noPreviewExtCheck(plainUrl, extension: string = null) {
    if (plainUrl) {
      let urlArray = plainUrl?.split('.');
      let ext = extension ? extension : '.' + urlArray[urlArray?.length - 1];
      let url = '';

      if (
        this.extArray.includes(ext) &&
        navigator.userAgent.indexOf('Mac OS X') == -1
      ) {
        url = './assets/images/no-preview.png';
      } else {
        url = plainUrl;
      }
      return url;
    }
  }
  fileEditorChange(fileName) {
    let urlArray = fileName?.split('.');
    let ext = urlArray[urlArray?.length - 1];
    if (fileName) {
      if (
        this.extArray.includes(ext) &&
        navigator.userAgent.indexOf('Mac OS X') == -1
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  getShortName(name: any) {
    if (name) {
      let nm = name.split(' ');
      let str = '';
      if (nm[2]) {
        str =
          nm[0].toString().charAt(0).toUpperCase() +
          nm[1].toString().charAt(0).toUpperCase();
      } else {
        str =
          nm[0].toString().charAt(0).toUpperCase() +
          nm[1].toString().charAt(0).toUpperCase();
      }

      // return name.split(' ').map(n => n[0]).join('').toUpperCase();
      return str;
    }
  }

  fileDownloadExtension(extension: string): Observable<string> {
    extension = extension.toLowerCase();
    const mimes = {
      '.z': 'application/zip-compress',
      '.zip': 'application/zip',
      '.gz': 'application/x-gzip',
      '.gzip': 'multipart/x-gzip',
      '.tar': 'application/x-tar',
      '.tgz': 'application/x-compressed',
      '.sitx': 'application/x-stuffitx',
      '7-zip': 'application/x-stuffitx',
      '.apk': 'application/zip',
      '.rar': 'application/rar',
      '.tif': 'image/tiff',
      '.tiff': 'image/tiff',
      '.dng': 'image/dng',
    };
    if (mimes[extension]) {
      return mimes[extension];
    }
    return null;
  }

  fileViewerRestrictedExtension(extension: string): Observable<string> {
    extension = extension.toLowerCase();
    const mimes = {
      '.log': 'text/plain',
      '.txt': 'text/plain',
      '.htm': 'text/html',
      '.html': 'text/html',
      '.htmls': 'text/html',
      '.htt': 'text/webviewhtml',
      '.htx': 'text/html',
      '.xml': 'text/xml',
    };
    if (mimes[extension]) {
      return mimes[extension];
    }
    return null;
  }

  fileUploadRestrictedExtension(extension: string) {
    extension = extension.toLowerCase();
    if (
      extension.match(/^audio\//) ||
      extension.match(/^video\//) ||
      extension == 'application/x-msdownload' ||
      extension.match('javascript')
    ) {
      return true;
    }
    return false;
  }

  downloadFiles(modelData: any) {
    if (modelData.type == undefined)
      modelData.type = this.fileDownloadExtension(modelData.file_extension);

    if (modelData.name == undefined) modelData.name = modelData.file_name;

    let data: any = this.getFileFromApi(modelData.plainUrl);
    let blob = new Blob([data], { type: modelData.type });
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', modelData.name);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  getFileFromApi(url: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getExtentionFromType(fileName: string) {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(fileName)[1]; // "txt"
    return '.' + ext;
  }

  getImageByFileType(messageType: string) {
    return messageType === 'text/plain'
      ? 'txt 1.png'
      : messageType === 'application/pdf'
      ? 'pdf 1.png'
      : messageType === 'application/x-zip-compressed'
      ? 'zip 2.png'
      : messageType === 'application/zip'
      ? 'zip 2.png'
      : messageType === 'application/rar'
      ? 'rar.png'
      : messageType === 'application/x-rar-compressed'
      ? 'rar.png'
      : messageType === 'application/msword'
      ? 'word.png'
      : messageType ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ? 'excel 1.png'
      : messageType === 'application/vnd.ms-excel'
      ? 'excel 1.png'
      : messageType ===
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ? 'ppt 1.png'
      : messageType === 'application/vnd.ms-powerpoint'
      ? 'ppt 1.png'
      : messageType === 'text/csv'
      ? 'csvicon.png'
      : messageType === 'text/html'
      ? 'html.png'
      : messageType === 'text/htm'
      ? 'html.png'
      : messageType === 'text/xml'
      ? 'xml.png'
      : messageType === 'text/comma-separated-values'
      ? 'csvicon.png'
      : messageType === 'application/octet-stream'
      ? 'csvicon.png'
      : messageType === 'audio/mpeg'
      ? 'audio-file 1.png'
      : messageType ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ? 'word.png'
      : 'other icon.png';
  }

  public baseUrl(filePath, blobUrl: boolean = false) {
    if (filePath?.indexOf(environment?.staging_file_base_path) < 0)
      filePath = environment?.staging_file_base_path + filePath;

    if (blobUrl) {
      this.src$ = this.getImageBlob(filePath).pipe(
        map((blob: Blob) => this.createImage(blob))
      );
      return this.src$;
    }
    return filePath;
  }

  getImageBlob(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  createImage(blob: Blob) {
    console.log('CREATE_IMAGE');
    let objectURL = window.URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  messageModelToggle(fileName) {
    const imgname = this.noPreviewExtCheck(fileName);
    if (imgname.search('no-preview') > 0) {
      return null;
    }
    return 'modal';
  }

  unicodeToUtf16(codePoint: any) {
    if (codePoint.includes('-')) {
      // If the input has a hyphen, split it into separate code points
      const codePoints = codePoint.split('-').map((cp) => parseInt(cp, 16));
      const utf16String = codePoints.map((cp) => {
        if (cp < 0x10000) {
          // BMP character (code points below 0x10000)
          return String.fromCharCode(cp);
        } else {
          // Non-BMP character, calculate surrogate pairs
          const highSurrogate = Math.floor((cp - 0x10000) / 0x400) + 0xd800;
          const lowSurrogate = ((cp - 0x10000) % 0x400) + 0xdc00;
          return (
            String.fromCharCode(highSurrogate) +
            String.fromCharCode(lowSurrogate)
          );
        }
      });
      return utf16String.length > 1
        ? utf16String[0].concat(utf16String[1])
        : utf16String[0];
    } else {
      if (codePoint < 0x10000) {
        // BMP character
        return [String.fromCharCode(codePoint)];
      } else {
        // Non-BMP character, calculate surrogate pairs
        const highSurrogate =
          Math.floor((codePoint - 0x10000) / 0x400) + 0xd800;
        const lowSurrogate = ((codePoint - 0x10000) % 0x400) + 0xdc00;
        const utf16String = [
          String.fromCharCode(highSurrogate),
          String.fromCharCode(lowSurrogate),
        ];
        console.log('uuuu:', utf16String);
        // return utf16String
        // .map(char => `u${char.charCodeAt(0).toString(16).padStart(4, '0')}`)
        // .join('-');
        return utf16String.length > 1
          ? utf16String[0].concat(utf16String[1])
          : utf16String[0];
      }
    }
  }
}
