<!-- <div class="row" *ngIf="joinStrip">
  <div class="top-call-sec d-flex justify-content-between py-2 px-5">
    <div class="d-flex justify-content-end w-100 pe-5">
      <div class="d-flex">
        <div class="me-2 call-icon">
          <img width="16px" src="./assets/images/phone.png" />
        </div>
        <p class="text-white fs-12">Call in Progress</p>
      </div>
    </div>

    <div class="me-5">
      <button type="button" (click)="joinFromStrip()" class="btn join-call">
        Join
      </button>

      <button
        type="button"
        class="btn-close btn-close-call btn-close-white float-end"
        (click)="closeStrip()"
      ></button>
    </div>
  </div>
</div> -->

<!-- call fixed sec -->
<app-calling-sound
  [isPlay]="showCallingPop"
  *ngIf="showCallingPop && !isCalled && !stopSound"
></app-calling-sound>
<div class="call-fixed-sec" *ngIf="showCallingPop">
  <img width="100px" [nexaeiImageSrc]="'call-nex-logo.png'" />

  <div class="d-flex justify-content-center pt-2">
    <div class="col-8">
      <div class="d-flex justify-content-center" *ngIf="isTeamCalling">
        <div class="img-sec-audio col-5" *ngIf="profileImage">
          <img width="100%" src="{{ profileImage }}" />
        </div>
        <div class="img-sec-audio col-5" *ngIf="!profileImage">
          {{ this.UtilsService.getShortName(displayName) }}
        </div>
      </div>
      <div class="d-flex justify-content-center" *ngIf="!isTeamCalling">
        <div class="img-sec-audio col-5">
          {{ displayName.charAt(0) | uppercase }}
        </div>
      </div>

      <p class="text-center w-100 fs-13 pt-3">{{ displayName }}</p>

      <p class="text-secondary text-center m-0 p-0 fs-11">Calling...</p>

      <div class="d-flex justify-content-center pt-3">
        <button
          type="button"
          class="btn btn-danger"
          (click)="rejectCall(message)"
        >
          Reject
        </button>

        <button
          type="button"
          class="btn btn-acpect ms-2"
          (click)="acceptCall(message)"
        >
          Accept
        </button>
      </div>
    </div>
  </div>
</div>

<!-- call fixed sec -->

<div
  class="d-flex justify-content-center align-items-center top-dip-sec"
  [ngClass]="{ draggable: isPIPVew }"
  *ngIf="isCalled"
>
  <div
    class="audiopopup"
    [ngStyle]="{
      height: height,
      width: width,
      top: top,
      left: left,
      transform: 'translate(' + position.x + 'px, ' + position.y + 'px)'
    }"
    (mousedown)="onMouseDown($event)"
  >
    <div class="maxcimize" (click)="changeFromPIPView()" *ngIf="isPIPVew">
      <img width="16px" src="./assets/images/minimise.png" />
    </div>

    <header
      class="d-flex justify-content-start align-items-center px-5 bg-dark py-2"
      *ngIf="!isPIPVew"
    >
      <div><img width="26px" src="./assets/images/mobile_logo.png" /></div>

      <div class="make_click d-flex ps-3">
        <div class="profile-sec-call" *ngIf="isTeamCalling">
          <img
            width="100%"
            *ngIf="profileImage != null && profileImage != ''"
            src="{{ UtilsService.baseUrl(profileImage) }}"
          />
          <span *ngIf="this.profileImage == '' || this.profileImage == null">
            {{ this.UtilsService.getShortName(displayName) }}
          </span>
        </div>
        <div class="profile-sec-call" *ngIf="!isTeamCalling">
          <span>
            {{ displayName.charAt(0) }}
          </span>
        </div>
        <div class="ms-2">
          <p class="p-0 m-0">{{ displayName }}</p>
          <p class="p-0 m-0 text-muted fs-12">{{ callTime }}</p>
        </div>
      </div>

      <div class="d-flex">
        <!-- <div>
          <button
            type="button"
            class="btn details-delet text-muted disable-input"
          >
            <img width="16px" src="./assets/images/man-icon.png" />
          </button>

          <button
            type="button"
            class="btn details-delet text-muted disable-input"
          >
            <img width="16px" src="./assets/images/mick.png" />
          </button>

          <button type="button" class="btn btn-danger">Leave</button>
        </div> -->

        <!-- <div
          class="ps-3"
          style="cursor: pointer"
          *ngIf="!Z"
          (click)="changeToPIPView()"
        >
          <img width="25px" [nexaeiImageSrc]="'maximize.png'" />
        </div> -->
        <div
          class="ps-3"
          style="cursor: pointer"
          *ngIf="isPIPVew"
          (click)="changeFromPIPView()"
        >
          <img width="25px" [nexaeiImageSrc]="'maximize.png'" />
        </div>
      </div>
    </header>

    <div class="clearfix"></div>

    <div class="d-flex w-100">
      <div
        class="left-fixed d-flex pt-5 col-1 d-none d-lg-flex"
        *ngIf="!isPIPVew"
      >
        <div>
          <ul>
            <li class="show-hand">
              <a (click)="redirectPage('home')">
                <img width="25px" [nexaeiImageSrc]="'sidebar/home.png'" />
              </a>
            </li>

            <li class="show-hand">
              <a (click)="redirectPage('chat')">
                <img width="25px" [nexaeiImageSrc]="'sidebar/all-colab.png'" />
              </a>
            </li>

            <li class="show-hand">
              <a (click)="redirectPage('calendar')">
                <img width="25px" [nexaeiImageSrc]="'sidebar/calendar.png'" />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <app-audiopopup
          [isPIP]="isPIPVew"
          (timer)="setTimer($event)"
          (leaveMeeting)="leaveMeeting($event)"
          (participantLeft)="participantLeft($event)"
          [meetingUrl]="meetingUrl"
          [isTeam]="isTeam"
          [callType]="callType"
          [channelType]="channelType"
          [isTeamCalling]="isTeamCalling"
          (participantJoinedCountMoreThanOnefunc)="
            participantJoinedCountMoreThanOne($event)
          "
        ></app-audiopopup>
      </div>

      <!-- <div
        class="col-5 col-md-3 pt-2 border-start border-dark ps-2 right-sec-au ps-3 pe-3"
      >
        <div
          class="top-sec d-flex justify-content-between border-bottom border-secondary pt-2 border-1"
        >
          <p>Participants</p>
          <button
            type="button"
            class="btn-close btn-close-white"
            aria-label="Close"
          ></button>
        </div>

         <form class="d-flex">
         <div  class="search"><div  class="position-relative">
            <img  class="search-small" src="./assets/images/searchsmall.png"></div>
            <input  type="text" placeholder="Search members and teams" class="searchTerm">
           </div>
       </form> 

        <div class="mt-3">
          <div class="top-sec d-flex justify-content-between">
            <p class="fs-12 text-muted">In this meeting (5)</p>

             <button type="button" class="btn btn-dark">Mute all</button> 
          </div>

          <div class="d-flex justify-content-between pt-1 pb-2">
            <div class="make_click d-flex">
              <div class="mt-1">
                <img
                  src="https://media.nexkul.com/org_507/ProfilePicture/user_127862/Thumb/47680c7a-a329-4f2c-b5f5-47043d5c73a4.jpg"
                />
              </div>

              <div>
                <p class="p-0 m-0">Mona Gupta</p>
                <p class="fs-12 text-muted p-0 m-0">Host</p>
              </div>
            </div>

            <div class="mt-1">
              <img width="15px" [nexaeiImageSrc]="'mick.png'" />
            </div>
          </div>

          <div class="d-flex justify-content-between pt-1 pb-2">
            <div class="make_click d-flex">
              <div class="user-name">BZ</div>

              <div>
                <p class="p-0 m-0">Mona Gupta</p>
                <p class="fs-12 text-muted p-0 m-0">Member</p>
              </div>
            </div>

            <div><img width="15px" [nexaeiImageSrc]="'mick.png'" /></div>
          </div>

          <div class="d-flex justify-content-between pt-1 pb-2">
            <div class="make_click d-flex">
              <div class="mt-1">
                <img
                  src="https://media.nexkul.com/org_507/ProfilePicture/user_127862/Thumb/47680c7a-a329-4f2c-b5f5-47043d5c73a4.jpg"
                />
              </div>

              <div>
                <p class="p-0 m-0">Mona Gupta</p>
                <p class="fs-12 text-muted p-0 m-0">Member</p>
              </div>
            </div>

            <div><img width="15px" [nexaeiImageSrc]="'mick.png'" /></div>
          </div>
        </div>

        <div class="mt-3">
          <div class="top-sec d-flex justify-content-between">
            <p class="fs-12 text-muted">Others Invited (2)</p>

             <button type="button" class="btn btn-dark">Mute all</button> 
          </div>

          <div class="d-flex justify-content-between pt-1 pb-2">
            <div class="make_click d-flex">
              <div class="mt-1">
                <img
                  src="https://media.nexkul.com/org_507/ProfilePicture/user_127862/Thumb/47680c7a-a329-4f2c-b5f5-47043d5c73a4.jpg"
                />
              </div>

              <div>
                <p class="p-0 m-0">Mona Gupta</p>
                <p class="fs-12 text-muted p-0 m-0">Member</p>
              </div>
            </div>

            <div class="mt-1">
              <img width="15px" [nexaeiImageSrc]="'mick.png'" />
            </div>
          </div>

          <div class="d-flex justify-content-between pt-1 pb-2">
            <div class="make_click d-flex">
              <div class="user-name">BZ</div>

              <div>
                <p class="p-0 m-0">Mona Gupta</p>
                <p class="fs-12 text-muted p-0 m-0">Member</p>
              </div>
            </div>

            <div><img width="15px" [nexaeiImageSrc]="'mick.png'" /></div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!-- Component tobe moved inside authorized-->
<app-calling-screen
  *ngIf="isCalling"
  [meetingUrl]="meetingUrl"
  [callingName]="displayName"
  [profileImage]="profileImage"
></app-calling-screen>
<app-calling-sound [isPlay]="isCalling"></app-calling-sound>
<!-- Component tobe moved inside authorized-->
<router-outlet></router-outlet>

<div class="row position-relative" *ngIf="notification">
  <div
    class="push-notification"
    style="cursor: pointer"
  >
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
	  (click)="closeCollab()"
    ></button>
    <div class="d-flex" (click)="emptyCollab()">
      <div class="newmssage">
        <img width="36px" [nexaeiImageSrc]="'s-logo.png'" />
      </div>

      <p class="ps-2 newmessagefont">You've got a new chat</p>
    </div>
    <div class="d-flex" (click)="emptyCollab()">
      <div class="emtpy-img" *ngIf="profileImage && isTeamCalling">
        <img src="{{ profileImage }}" />
      </div>
      <div *ngIf="!isTeamCalling" class="emtpy-img"
	  [ngStyle]="{ 'background-color': colorService.getColor(displayName) }"
	  >
		{{ displayName.charAt(0) }}
      </div>
	  <div class="emtpy-img" *ngIf="!profileImage && isTeamCalling" 
	  [ngStyle]="{ 'background-color': colorService.getColor(displayName) }"
	  >
        {{ message.sender.name.charAt(0) }}
      </div>
      <div>
        <p *ngIf="!isTeamCalling" class="fs-14 fw-bold mb-0">{{ displayName | truncateStringNotification:25 }}</p>
        <p class="fs-14 pb-1 mb-0">{{ message?.sender?.name | truncateStringNotification:25 }}</p>
        <p class="l-h m-0 p-0 fs-14 text-secondary word-wrap">{{ message?.message | truncateStringNotification:140 }}</p>
      </div>
    </div>

    <!--<form class="d-flex">
         <input class="form-control me-2 n-search" type="search" placeholder="Sure let’s connect today" aria-label="Search">
         <div  class="send "><a  class="disable-input ms-1 mt-1"><img width="40px" [nexaeiImageSrc]="'emp-invite/send.png'"></a></div>
       </form>-->
  </div>

  <!-- <div class="push-notification-small">
   
         <div class="ps-inner"> 
   
           <div class="d-flex">
          
            <div><img width="36px" [nexaeiImageSrc]="'s-logo.png'" />  </div>
      
            <p class="ps-2 pt-2">You've got a new chat</p>
            
          </div> 
      
      
          <div class="d-flex"> 
      
           <div class="pe-2">  <img class="profilepic-new " src="https://media.nexaei.com/org_335/ProfilePicture/user_2201/Thumb/7ef7a0f9-a055-4067-97cd-241542132b95.jpg">
           </div>
      
            <div>
              <p class="fs-14 pb-1 mb-0">Alex Thomas</p>
              <p class="l-h fs-14 text-secondary pb-0 mb-0">Hi can we have call?</p>
            
            </div>
        
      
          </div>
      
      
         </div>
   
         <div class="ps-inner">  
   
         <div class="d-flex">
        
          <div><img width="36px" [nexaeiImageSrc]="'s-logo.png'" />  </div>
    
          <p class="ps-2 pt-2">You've got a new chat</p>
          
        </div> 
    
    
        <div class="d-flex"> 
    
         <div class="pe-2">  <img class="profilepic-new " src="https://media.nexaei.com/org_335/ProfilePicture/user_2201/Thumb/7ef7a0f9-a055-4067-97cd-241542132b95.jpg">
         </div>
    
          <div>
            <p class="fs-14 pb-1 mb-0">Alex Thomas</p>
            <p class="l-h fs-14 text-secondary pb-0 mb-0">Delves into fundamental principles such as simplicity, consistency...</p>
          
          </div>
      
    
        </div>
    
    
        <form class="d-flex mt-3">
          <input class="form-control me-2 n-search" type="search" placeholder="Sure let’s connect today" aria-label="Search">
          <div  class="send "><a  class="disable-input ms-1 mt-1"><img width="40px" [nexaeiImageSrc]="'emp-invite/send.png'"></a></div>
        </form>
    
       </div>
    
    
    
        </div> -->
</div>
