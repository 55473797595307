import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '../interfaces/http-response.interface';
import * as moment from 'moment';
import 'moment-timezone';
import {
  SendbirdUserData,
  colleagueList,
  getAllActiveUsers,
  getAllInvitedUsers,
  getAllArchivedUsers,
  rwmoveActiveMembers,
  reActiveMembers,
  getUserProfile,
  udpateUserProfile,
  getUserDeviceRequest,
  approveDeviceRequest,
  deviceRequestCheckUncheck,
  getTeamWiseAnnouncer,
  earlyBirdUser,
  forgotPassword,
  forgotPassVerifyOTP,
  resetPass,
  allUsers,
  EditUserEmail,
  UpdateUserIsSearchMember,
  ConfirmUserNewEmail,
  ResendUserEmail,
  searchFiles,
  AllowSearchRestriction,
  VerifyUserRegion,
  GetAllManageUser,
  GetAllStatus,
  FilterManageUser,
  GetManageUserReport,
  GetReportProcessStatus,
  FilterManageTeam,
  addUpdateColabOrgConfig,
  AllowDownloadRestriction,
  UpdateUserDownloadPermission,
  AllowScreenShotRestriction,
} from '../interfaces/user.interface';
import { AnyMxRecord } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private endpoint: string = environment.endpoint;
  private endpoint2: string = environment.endpoint2;
  constructor(private httpClient: HttpClient) {}
  selectedSendbirdUserData(data: SendbirdUserData) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetUserByCommunicationDetails',
      data
    );
  }
  getColleagueList(data: colleagueList) {
    if (
      localStorage.getItem('org_name') != '-----' &&
      localStorage.getItem('org_name') != null &&
      localStorage.getItem('invite-string') == null
    ) {
      return this.httpClient.post<HttpResponse>(
        this.endpoint + 'api/User/GetColleaugueList',
        data
      );
    }
    return null;
  }

  getAllUsers(data: allUsers) {
    if (
      localStorage.getItem('org_name') != '-----' &&
      localStorage.getItem('org_name') != null &&
      localStorage.getItem('invite-string') == null
    ) {
      
      return this.httpClient.post<HttpResponse>(
        this.endpoint + 'api/User/GetAllUsersWithStatus',
        data
      );
    }
    return null;
  }
  getAllActiveUsers(data: getAllActiveUsers) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllActiveUsers',
      data
    );
  }
  getAllInvitedUsers(data: getAllInvitedUsers) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllInviteUsers',
      data
    );
  }
  getAllArchivedUsers(data: getAllArchivedUsers) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllInactiveUsers',
      data
    );
  }
  rwmoveActiveMembers(data: rwmoveActiveMembers) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/RevokeUsersFromOrg',
      data
    );
  }
  reActiveMembers(data: reActiveMembers) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/ReActivateUser',
      data
    );
  }

  getUserProfile(data: getUserProfile) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetUserProfileDetails_V1',
      data
    );
  }
  udpateUserProfile(data: udpateUserProfile) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/UpdateUserProfileDetails',
      data
    );
  }
  getUserDeviceRequest(data: getUserDeviceRequest) {
    return this.httpClient.post(
      this.endpoint + 'api/User/GetDeviceRegistrationRequestDetailsForUser',
      data
    );
  }
  approveDeviceRequest(data: approveDeviceRequest) {
    return this.httpClient.post(
      this.endpoint + 'api/User/ApproveDeviceRegistrationRequest',
      data
    );
  }
  deviceRequestCheckUncheck(data: deviceRequestCheckUncheck) {
    return this.httpClient.post(
      this.endpoint +
        'api/OrgAccount/ActiveAndDeactivateDeviceRegistrationService',
      data
    );
  }
  getTeamWiseAnnouncer(data: getTeamWiseAnnouncer) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllUsersBaesdonHierarchy',
      data
    );
  }
  earlyBirdUser(data: earlyBirdUser) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetEarlybirdUser',
      data
    );
  }
  forgotPassword(data: forgotPassword) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/SendOtpForForgotPassword',
      data
    );
  }
  forgotPassVerifyOTP(data: forgotPassVerifyOTP) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/VerifyUserOtp',
      data
    );
  }
  resetPass(data: resetPass) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/ResetUserPassword',
      data
    );
  }
  EditUserEmail(data: EditUserEmail) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/EditUserEmail',
      data
    );
  }
  UpdateUserIsSearchMember(data: UpdateUserIsSearchMember) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/UpdateUserIsSearchMember',
      data
    );
  }

  ConfirmUserNewEmail(data: ConfirmUserNewEmail) {
    let timezone = moment.tz.guess();
    const header = new HttpHeaders().set('TimeZone', timezone);
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/ConfirmUserNewEmail',
      data,
      { headers: header }
    );
  }

  ResendUserEmail(data: ResendUserEmail) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/ResendUserEmail',
      data
    );
  }
  GetAllOtherTeamAdmin(data: any) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllOtherTeamAdmin',
      data
    );
  }

  searchFiles(data: searchFiles) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/attachment/SearchFiles',
      data
    );
  }

  AllowSearchRestriction(data: AllowSearchRestriction) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/AllowSearchRestriction',
      data
    );
  }
  VerifyUserRegion(data: VerifyUserRegion) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/VerifyUserRegion',
      data
    );
  }
  GetAllManageUser(data: GetAllManageUser) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllManageUser',
      data
    );
  }
  GetAllStatus(data: GetAllStatus) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllStatus',
      data
    );
  }
  SearchManageUser(data: AnyMxRecord) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/SearchManageUser',
      data
    );
  }
  filterManageUser(data: FilterManageUser) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetAllManageUserFilterWise',
      data
    );
  }
  filterManageTeam(data: FilterManageTeam) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/Hierarchy/GetHierarchyList',
      data
    );
  }
  getManageUserReport(data: GetManageUserReport) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetManageUserReport',
      data
    );
  }
  getReportProcessStatus(data: GetReportProcessStatus) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint2 + 'attendance/Attendance/GetReportProcessStatus',
      data
    );
  }
  addUpdateColabOrgConfig(data: addUpdateColabOrgConfig) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/AddUpdateColabOrgConfig',
      data
    );
  }
  AllowDownloadRestriction(data: AllowDownloadRestriction) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/AddUpdateColabOrgConfig',
      data
    );
  }
  UpdateUserDownloadPermission(data: UpdateUserDownloadPermission) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/UpdateUserDataConfig',
      data
    );
  }
  AllowScreenShotRestriction(data: AllowScreenShotRestriction) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/OrgAccount/AddUpdateColabOrgConfig',
      data
    );
  }
}
