import { Injectable } from '@angular/core';
import {
  BaseMessage,
  FileMessageCreateParams,
  MessageListParams,
  PreviousMessageListQueryParams,
  ReplyType,
  UserMessageCreateParams,
  UserMessageUpdateParams,
} from '@sendbird/chat/message';
import {
  GroupChannel,
  GroupChannelHandler,
  GroupChannelModule,
  Member,
} from '@sendbird/chat/groupChannel';
import SendbirdChat, { BaseChannel } from '@sendbird/chat';
import { SendbirdCustomService } from './sendbird-custom.service';

@Injectable({
  providedIn: 'root'
})
export class SendCallingMessageService {
  channel: any;
  customTypes = [];
  callType: string = null;

  constructor(public sbservice: SendbirdCustomService) {
    this.customTypes['call_started']  = 'CALL-STARTED';
    this.customTypes['call_received'] = 'CALL-RECEIVED';
    this.customTypes['call_ended'] 	  = 'CALL-ENDED';
    this.customTypes['call_joined']   = 'CALL-JOINED';
    this.customTypes['call_rejected'] = 'CALL-REJECTED';
    this.customTypes['call_missed']   = 'CALL-MISSED';
    this.customTypes['call_user_busy']   = 'CALL-USER-BUSY';
  }
  
  async sbConnect(meetingUrl: any, callType: string) {
    await this.sbservice
      .sbConnect()
      .then(() => {
        this.sbGetGroupChannel(meetingUrl, callType);
      })
      .catch((error) => {
        console.log('SendBird connection failed', this.constructor, error);
      });
  }
  
  async sbGetGroupChannel(channelUrl: string, callType: string) {
    if (channelUrl != null) {
      await this.sbservice
        .sbGetGroupChannel(channelUrl)
        .then((channel: any) => {
          if (channel.url === channelUrl) {
            this.sbSendTextMessage(channel, this.customTypes[callType]);
          }
        })
        .catch((error) => {
          console.log('SendBird sbGetGroupChannel failed', error);
        });
    }
  }

  sbSendTextMessage = async (channel: any, textMessage: string) => {
    textMessage = textMessage.replace(/^\s+/g, '');
    if (textMessage == null && textMessage.length == 0) return;

    let messageDetail: UserMessageCreateParams | undefined;
    const PARAMS: UserMessageCreateParams = {
      message: textMessage,
      customType: textMessage,
    };

    messageDetail = PARAMS;

    if (messageDetail) {
      try {
        const result: any = await this.sbservice.sendUserMessage(
          channel,
          PARAMS
        ).then(() => {
        this.closeSendbirdChannel(channel?.url);
		});
      } catch (error) {
        // Handle error
        console.log(error.status);
        console.log(error);
      }
    }
  };
  
  closeSendbirdChannel(channelUrl) {
    try {
      if (channelUrl) {
        this.sbservice.sb.groupChannel.removeGroupChannelHandler(channelUrl);
      }
    } catch (e) {
      console.log('connection error:', e);
    }
  }
}
