import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorsService {
  constructor() {}
  colors = [
    '#3CB043',
    '#F45147',
    '#FF9900',
    '#FF641A',
    '#182B51',
    '#D454AA',
    '#C2713E',
    '#3290C5',
  ];
  colorsUsers = ['#ffffff'];

  getColor(label: string): string {
    if (!label) return;
    // const firstLetter = label[0];
    // const index = this.hash(label.charCodeAt(0))  % this.colors.length;
    const index = this.mapCharToIndex(label.substr(0, 2));
    return this.colors[index % this.colors.length];
  }

  getColorIndividual(label: string) {
    // const firstLetter = label[0];
    // const index = this.mapCharToIndex(label.substr(0, 2));
    // return this.colorsUsers[index % this.colorsUsers.length];
    return this.colorsUsers[0];
  }
  mapCharToIndex(c: string): number {
    return c.charCodeAt(0) + c.charCodeAt(1);
  }

  attendenceColor(timein, timesout, latest_punch_type) {
    // let mydate = moment(new Date(timest))
    // .add(330, 'minutes')
    // .format('YYYY-MM-DD');
    // let ToDate = moment(new Date()).format('YYYY-MM-DD');
    // let cls: any;
    // const dateIsAfter: any = '';
    // if (
    // timestout &&
    // timestout != '0001-01-01T00:00:00' &&
    // timestout != null &&
    // timestout != ''
    // ) {
    // }
    // if (dateIsAfter && dateIsAfter != '') {
    // if (mydate < ToDate) {
    // cls = 'border-red';
    // } else {
    // if (latest_punch_type == 'IN') {
    // cls = 'border-green';
    // } else {
    // cls = 'border-gray-n';
    // }
    // }
    // } else {
    // if (latest_punch_type == 'IN') {
    // if (mydate < ToDate) {
    // cls = 'border-red';
    // } else {
    // cls = 'border-green';
    // }
    // } else {
    // cls = 'border-gray-n';
    // }
    // }
    // this.unreadMessagecss = cls;
    // return cls;
  }
}
