import { Injectable } from '@angular/core';
import { AuthHttpService } from './auth-http.service';
import { EventEmitterService } from './event-emitter.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PermissionDataService } from 'src/app/service/permission-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupNotificationComponent } from '../templates/authorized/popup-notification/popup-notification.component';
import { CommunicationService } from 'src/app/service/communication.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileImageService {
  public latestCheckoutUrl: string = '';
  public latestCheckoutDesc: string = '';
  public latestProfileUrl: string;
  popupOpened: boolean = true;
  modalDialog: any;

  constructor(
    private authHttp: AuthHttpService,
    private eventEmitterService: EventEmitterService,
    private router: Router,
    private permissionService: PermissionDataService,
    private dialog: MatDialog,
    private comm: CommunicationService
  ) {
    this.latestProfileUrl = null;
  }

  fectchLoginData() {
    //this.authHttp.FindUserByTokenV1().subscribe(
    this.authHttp.FindUserByTokenV1().subscribe(
      (response) => {
        if (
          response.data['status_name'] == 'ACTIVE' ||
          response.data['status_name'] == 'INVITED'
        ) {
          localStorage.setItem('created_on', response.data['createdon']);
          localStorage.setItem('user-role', response.data['role_name']);
          localStorage.setItem(
            'is12hour_timeformat',
            response.data?.is12hour_timeformat?.char_value
          );
          localStorage.setItem(
            'user-punch-type',
            response.data?.latest_punch_type
          );
          if (
            response.data['userpicture_original'] != null &&
            response.data['userpicture_original'] != ''
          ) {
            localStorage.setItem(
              'user-profile-pic',
              response.data['userpicture_original'].replace(
                'http://',
                'https://'
              )
            );
            localStorage.setItem(
              'user-profile-pic-thumb',
              response.data['userpicture'].replace('http://', 'https://')
            );
            this.latestProfileUrl = response.data['userpicture'].replace(
              'http://',
              'https://'
            );
          } else {
            localStorage.removeItem('user-profile-pic-thumb');
            this.latestProfileUrl = null;
          }
		  const fullname = response.data?.fname + ' ' + response.data?.lname;
          localStorage.setItem('current_fname', response.data?.fname);
          localStorage.setItem('current_lname', response.data?.lname);
          localStorage.setItem('fullname', fullname);
          localStorage.setItem('current_designation', response.data?.designation);
          localStorage.setItem('is_watcher', response.data?.is_watcher);
          localStorage.setItem('is_announcer', response.data?.is_announcer);
          localStorage.setItem('user-check-in', response.data?.latest_check_in);
		  localStorage.setItem(
            'IS_ALLOWED_FILE_DOWNLOAD',
            response.data?.filedownload_org_dataconfig_details?.char_value
          );
		  localStorage.setItem(
            'IS_ALLOWED_USER_FILE_DOWNLOAD',
            response.data?.filedownload_user_dataconfig_details?.char_value
          );
		  localStorage.setItem(
            'IS_AUTO_RESET_OUT_TO_IN',
            response.data?.autoReset_Out_dataconfig?.char_value
          );
		  localStorage.setItem(
            'IS-ALLOWED-SCREENSHOT-ORG',
            response.data?.isScreenshot_org_dataconfig_details?.char_value
          );
		  localStorage.setItem(
            'is12hour_timeformat',
            response.data?.is12hour_timeformat?.char_value
          );

          localStorage.setItem(
            'leadEmailVerify',
            response.data?.leadEmailVerify
          );
          localStorage.setItem(
            'user-check-out',
            response.data?.latest_check_out
          );

          if (response.data['latest_check_out_statusurl'] != null) {
            this.latestCheckoutUrl =
              response.data['latest_check_out_statusurl'];
            this.latestCheckoutDesc =
              response.data['latest_check_out_statusdesc'];
          }
          localStorage.setItem(
            'is_search_allowed',
            response?.data?.is_searchmember
          );
          localStorage.setItem('user-email', response.data?.email);
          localStorage.setItem(
            'dr',
            response.data?.dataconfig_details?.char_value
          );
          if (
            response.data?.regions_id &&
            localStorage.getItem('regions_id') == 'rVJO3lG/mOFqgnBQKfD4WA=='
          ) {
            localStorage.setItem('regions_id', response.data?.regions_id);
            localStorage.setItem('region_name', response.data?.regionname);
            if (
              localStorage.hasOwnProperty('new_region_id') &&
              localStorage.getItem('new_region_id') ==
                'rVJO3lG/mOFqgnBQKfD4WA=='
            )
              localStorage.setItem('new_region_id', response.data?.regions_id);
          }

          if (
            localStorage.getItem('roleid') != null &&
            localStorage.getItem('roleid') != 'undefined'
          ) {
            const old_role = localStorage.getItem('roleid');
            if (
              response.data?.roleid != old_role &&
              old_role != 'rVJO3lG/mOFqgnBQKfD4WA=='
            ) {
              //show the popup and reload the page
              localStorage.removeItem('new_region_id');
              if (response.data['role_name'] == 'SUPERADMIN') {
                localStorage.setItem('new_region_id', response.data?.regions_id);
              }
              localStorage.setItem('roleid', response.data?.roleid);
              this.openPopup(old_role, response.data?.roleid, 'RoleCghange');
            } else localStorage.setItem('roleid', response.data?.roleid);
          }

          if (localStorage.getItem('org_name') != null) {
            const old_org_name = localStorage.getItem('org_name');
            if (response.data?.orgdetails?.orgname != old_org_name) {
              //show the popup and reload the page
              localStorage.setItem(
                'org_name',
                response.data?.orgdetails?.orgname
              );
            }
          }

          if (
            response.data?.searchrestriction_dataconfig_details != 'null' &&
            response.data?.searchrestriction_dataconfig_details?.char_value ==
              'Y'
          ) {
            localStorage.setItem('is_allowed_search_restriction', 'Y');
          } else if (
            response.data?.searchrestriction_dataconfig_details != 'null' &&
            response.data?.searchrestriction_dataconfig_details?.char_value ==
              'N'
          ) {
            localStorage.setItem('is_allowed_search_restriction', 'N');
          } else {
            localStorage.setItem('is_allowed_search_restriction', 'Y');
          }

          this.comm.updateLatestByFindToken$.next('true');
          this.comm.loadNewAnnouncementMessageCount$.next('true');
          //  get UI config
          this.permissionService.getPermissionMatrixFromHttp();
        } else {
          this.router.navigate(['/dashboard/logout']);
        }
      },
      (error) => {
        //this.isSubmitFormLoading = false;
      }
    );
  }
  openPopup(flg: string = null, flgTo: string = null, type: string = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '50vw';
    dialogConfig.panelClass = 'borderless-dialog';
    dialogConfig.panelClass = 'bck-remove';
    dialogConfig.data = {
      chkFlag: flg,
      chkFlagTo: flgTo,
      chkType: type,
    };
    this.modalDialog = this.dialog.open(
      PopupNotificationComponent,
      dialogConfig
    );
  }

  closeOrgDeactivate() {
    this.modalDialog.close();
  }
}
