<div class="d-flex justify-content-center align-items-center top-dip-sec">
  <app-audiopopup style="display: none"></app-audiopopup>

  <div
    class="audiopopup"
    [ngStyle]="{ height: height, width: width, top: top, left: left }"
  >
    <header
      class="d-flex justify-content-between align-items-center px-5 bg-dark py-2"
    >
      <div class="make_click d-flex">
        <div class="profile-sec-call">
          <img
            width="100%"
            src="{{ UtilsService.baseUrl(profileImage) }}"
            *ngIf="
              this.profileImage != 'null' &&
              this.profileImage != null &&
              this.profileImage != ''
            "
          />
          <span
            *ngIf="
              this.profileImage == 'null' ||
              this.profileImage == null ||
              this.profileImage == ''
            "
            >{{ UtilsService.getShortName(callingName) }}</span
          >
          <!-- <span
            *ngIf="
              this.profileImage == 'null' ||
              this.profileImage == null ||
              this.profileImage == ''
            "
            >{{ callingName.charAt(0) }}</span
          > -->
        </div>
        <div class="ms-2">
          <p class="p-0 m-0">{{ callingName }}</p>
          <p class="p-0 m-0 text-muted fs-12"></p>
        </div>
      </div>

      <div>
        <!-- <button
          type="button"
          class="btn details-delet text-muted disable-input"
        >
          <img width="16px" src="./assets/images/man-icon.png" />
        </button>

        <button
          type="button"
          class="btn details-delet text-muted disable-input"
        >
          <img width="16px" src="./assets/images/mick.png" />
        </button> -->

        <button type="button" (click)="leaveMeeting()" class="btn btn-danger">
          Leave
        </button>
      </div>
    </header>

    <div class="clearfix"></div>

    <div class="d-flex w-100">
      <div
        class="d-flex col-12 aud-sec p-4 justify-content-center align-items-center mt-0"
      >
        <div class="col-2">
          <div class="img-sec-audio">
            <img
              width="100%"
              src="{{ UtilsService.baseUrl(this.profileImage) }}"
              *ngIf="
                this.profileImage != 'null' &&
                this.profileImage != null &&
                this.profileImage != ''
              "
            />
            <span
              *ngIf="
                this.profileImage == 'null' ||
                this.profileImage == null ||
                this.profileImage == ''
              "
              >{{ UtilsService.getShortName(callingName) }}</span
            >
            <!-- <span
              *ngIf="this.profileImage != 'null' || this.profileImage != null"
              >{{ callingName.charAt(0) }}</span
            > -->
          </div>

          <p>{{ callingName }}</p>

          <p class="text-secondary text-center m-0 p-0">Calling...</p>
        </div>
      </div>
    </div>
  </div>
</div>
