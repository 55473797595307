import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { JwtService } from './jwt.service';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AuthHttpService } from './auth-http.service';
import { Router } from '@angular/router';
import { EventEmitterService } from './event-emitter.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OrgDeactivatePopupComponent } from '../templates/authorized/org-deactivate-popup/org-deactivate-popup.component';
import { map } from 'rxjs/operators';
import { HttpResponseOrgDeact } from '../interfaces/http-response.interface';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileImageService } from 'src/app/service/profile-image.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  popupOpened: boolean = true;
  modalDialog: any;
  constructor(
    private jwt: JwtService,
    private router: Router,
    private authService: AuthHttpService,
    private dialog: MatDialog,
    private eventEmitter: EventEmitterService,
    public prImage: ProfileImageService
  ) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
	if(request.responseType != 'blob') 
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    const token = this.jwt.getTokenFromLocalStorage();
    if (request.responseType != 'blob')
      request = request.clone({
        headers: request.headers
          .set('Authorization', 'Bearer ' + token)
          .set('Strict-Transport-Security', 'max-age=60000')
          .set('X-Frame-Options', 'DENY'),
      });
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: this.actionInterceptor(event.body) });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401) {
          return this.authService.refreshToken(error.url).pipe(
            switchMap(() => {
              return this.authService.accessToken$.pipe(
                first(),
                switchMap((accessToken) => {
                  request = request.clone({
                    setHeaders: {
                      authorization: `Bearer ${accessToken}`,
                    },
                  });
                  return next.handle(request);
                })
              );
            }),
            catchError((err) => {
              // Redirect the user to the login page
              this.router.navigate(['/dashboard/logout']);
              // this.router.navigate(['/dashboard/logout']);
              return throwError(err);
            })
          );
        } else if (error.status === 403) {
           this.router.navigate(['/dashboard/logout']);
        }

        return throwError(error);
      })
    );
  }
  private actionInterceptor(body: HttpResponseOrgDeact) {
    if (body.code && body.code == 423 && this.popupOpened == true) {
      if (this.router.url != '/login') {
        if (localStorage.getItem('user-role') == 'SUPERADMIN') {
          this.openOrgDeactivate(true);
        } else {
          this.openOrgDeactivate(false);
        }
      }
      this.popupOpened = false;
      return false;
    } else if (body.code && body.code == 405 && body.success == false) {
      this.prImage.fectchLoginData();
      return false;
    }
  }
  openOrgDeactivate(flg: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '50vw';
    dialogConfig.panelClass = 'borderless-dialog';
    dialogConfig.panelClass = 'bck-remove';
    dialogConfig.data = {
      chkFlag: flg,
    };
    this.modalDialog = this.dialog.open(
      OrgDeactivatePopupComponent,
      dialogConfig
    );
  }
  closeOrgDeactivate() {
    this.modalDialog.close();
  }
}
