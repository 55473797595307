import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { JitsiService } from 'src/app/service/jitsi.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilsService } from 'src/app/service/utils.service';
import { Modal } from 'bootstrap';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { EventEmitter } from '@angular/core';
import { AudioVideoCallApiService } from 'src/app/service/audio-video-call-api.service';
import {
  saveGroupCallInfo,
  getGroupCallInfo,
} from 'src/app/interfaces/audio-video-call';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-audiopopup',
  templateUrl: './audiopopup.component.html',
  styleUrls: ['./audiopopup.component.scss'],
})
export class AudiopopupComponent implements OnInit {
  @Output() timer: EventEmitter<any> = new EventEmitter();
  @Output() leaveMeeting: EventEmitter<any> = new EventEmitter();
  @Output() participantLeft: EventEmitter<any> = new EventEmitter();
  @Output() participantJoinedCountMoreThanOne: EventEmitter<any> =
    new EventEmitter();
  @Input() isPIP: boolean;
  @Input() meetingUrl: any = null;
  @Input() isRejected: boolean = false;
  @Input() callType: string;
  @Input() channelType: string;
  @Input() isTeamCalling: boolean;
  @ViewChild('pipVideo') pipVideo: ElementRef;
  @ViewChild('take_call_modal', { static: false })
  take_call_modal: ElementRef | null = null;
  isCalling: boolean = false;
  sub: Subscription;
  myParam: string;
  selectedColleague: string;
  all_users: any;
  singleColleaagueDate: any;
  takeCallModal: Modal | null = null;
  minimize: boolean = false;
  private api: any;
  jitsiWidth = '1195px';
  jitsiHeight = '92vh';
  private callStartTime: number = 0;
  public callDuration: string = '00:00';
  channel: any;
  customTypes = [];
  totalParticipants: number = 0;
  timerInterval: any;
  userJitsiId: string;

  constructor(
    private router: Router,
    public jitsiService: JitsiService,
    private route: ActivatedRoute,
    private UtilsService: UtilsService,
    private eventEmitterService: EventEmitterService,
    private audioVideoCallApiService: AudioVideoCallApiService
  ) {}

  ngOnChanges() {
    if (this.isPIP) {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.style.width = '300px';
        iframe.style.height = '255px';
      }
    } else {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.style.width = '1195px';
        iframe.style.height = '92vh';
      }
    }
  }
  ngAfterViewInit(): void {
    this.takeCallModal = new Modal(this.take_call_modal?.nativeElement);
  }

  ngOnInit(): void {
    this.initJitsi();

    this.sub = this.route.params.subscribe(
      (params) => (this.myParam = params['param'])
    );
  }

  initJitsi() {
    const domain = 'webrtc.nexkul.com/';
    const options = {
      roomName: this.meetingUrl,
      width: this.jitsiWidth,
      height: this.jitsiHeight,

      configOverwrite: {
        prejoinPageEnabled: false,
        passwordRequired: false,
        startWithVideoMuted: true,
        startWithAudioMuted: true,
        hideAddRoomButton: true,
        disableHangup: true,
        'notifications.enabled': false,
        hideConferenceSubject: true,
        hideConferenceDuration: true,
        hideConferenceTimer: true,
        soundNotifications: {
          participantLeft: false, // Disables sound when a participant leaves
        },
      },
      interfaceConfigOverwrite: {
        // Add any config you need
        TOOLBAR_BUTTONS: [
          'microphone',
          'camera',
          //'desktop',
          'fullscreen',
          // 'fodeviceselection',
          'hangup',
          // 'profile',
          //'chat',
          //'settings',
          //'recording',
          // 'live-streaming',
          // 'etherpad',
          'raisehand',
          'participants-pane',
          //'shortcuts',
        ],
        NOTIFICATIONS_ENABLED: false,
        SHOW_ROOM_NAME: false,
        filmStripOnly: true, // Show only the current video
        SHOW_JITSI_WATERMARK: false, // Hide watermark
        SHOW_WELCOME_PAGE_TOOLTIP: false, // Hide welcome message
        TILE_VIEW: false, // Disable grid view
        SINGLE_PARTICIPANT_MODE: true, // Display only one participant
        VIDEO_LAYOUT_FIT: 'height', // Adjust video size
        SHOW_END_MEETING_BUTTON: false,
        SHOW_MEETING_TIME: false,
      },
      parentNode: document.querySelector('#jitsi-iframe'),

      userInfo: {
        displayName:
          localStorage.getItem('current_fname') +
          ' ' +
          localStorage.getItem('current_lname'),
        email: localStorage.getItem('user-email'),
        avatarUrl: this.UtilsService.baseUrl(
          localStorage.getItem('user-profile-pic-thumb')
        ),
      },
      notifications: {
        enabled: false, // Disable notifications
      },
    };

    this.api = new JitsiMeetExternalAPI(domain, options);
    this.api.addEventListeners({
      readyToClose: this.handleClose,
      // participantLeft: this.handleParticipantLeft,
      // participantJoined: this.handleParticipantJoined,
      // videoConferenceJoined: this.handleVideoConferenceJoined,
      videoConferenceLeft: this.handleVideoConferenceLeft,
      // audioMuteStatusChanged: this.handleMuteStatus,
      // videoMuteStatusChanged: this.handleVideoStatus,
      // participantRoleChanged: this.participantRoleChanged,
      // passwordRequired: this.passwordRequired,
      // endpointTextMessageReceived: this.endpointTextMessageReceived,
      // videoConferenceEnded: this.conferenceEnded,
    });
    this.api.executeCommand(
      'avatarUrl',
      this.UtilsService.baseUrl(localStorage.getItem('user-profile-pic-thumb'))
    );
    if (this.channelType == 'messaging') {
      this.api.executeCommand('setUserRole', 'participant');
    }

    //---- Called when I join the meeting----///
    this.api.addEventListener('videoConferenceJoined', (event) => {
      this.getParticipants().then((data) => {
        console.log('videoConferenceJoined', data);
      });
      this.userJitsiId = event.id; // This gives you your user ID
      console.log('videoConferenceJoined:', this.userJitsiId);
      this.callStartTime = performance.now();
      this.updateCallDuration();
      //channelType
      if (this.isTeamCalling) {
        setTimeout(() => {
          this.toggleParticipantsButton();
        }, 100);
      }
      //alert(this.userJitsiId);
    });
    //---- Called when any other participant apart of me Joins the meeting----///
    this.api.addEventListener('participantJoined', (event) => {
      this.getParticipants().then((data) => {
        console.log('videoConferenceJoined', data);
      });
      const participants = this.api.getNumberOfParticipants();
      this.totalParticipants = participants;
      if (this.totalParticipants == 2) {
        this.participantJoinedCountMoreThanOne.emit(true);
      }
      console.log('>>>>', this.totalParticipants);
      // if (this.totalParticipants <= 2) {
      // this.api.executeCommand('setToolbarButtonEnabled', 'hangup', false);
      // }
    });

    //---- Called when any other participant apart of me leaves the meeting----///
    this.api.addEventListener('participantLeft', (event) => {
      //alert('participantLeft');
      //alert(event.id);
      console.log('id participantLeft', event.id);
      let currentParticipantCount = this.api.getNumberOfParticipants();
      if (currentParticipantCount <= 1) {
        this.handleVideoConferenceLeft();
      }
    });
    //---- Called when I leave the meeting----///(this is not called)
    this.api.addEventListener('videoConferenceLeft', (event) => {
      //alert('videoConferenceLeft');
      console.log('id videoConferenceLeft', this.api);
      console.log('id videoConferenceLeft', this.userJitsiId);
      if (this.userJitsiId == event.id) this.participantLeft.emit(true);

      let currentParticipantCount = this.api.getNumberOfParticipants();
      // alert(currentParticipantCount);
      // alert('videoConferenceLeft');
      const thankYouMessage = document.getElementById('thank-you-message');
      if (thankYouMessage) {
        thankYouMessage.style.display = 'none';
      }
    });

    this.api.executeCommand('logLevel', 'info');

    //------------ Connenction listeners
    // this.api.addEventListener('connectionFailed', (event: any) => {
    //   console.error('Connection failed:', event);
    //   this.handleConnectionFailure();
    // });
    // this.api.addEventListener('connectionDisconnected', (event: any) => {
    //   console.log('Connection disconnected', event);
    //   this.handleConnectionFailure();
    // });
    // Handle connection established
    // this.api.addEventListener('connectionEstablished', (event: any) => {
    //   console.log('Connection established:', event);
    // });
    //------------ Connenction listeners
  }

  // Handle connection failure event
  handleConnectionFailure() {
    // Retry logic or user alert can go here
    alert('Connection failed. Retrying...');
    this.retryConnection();
  }
  // Retry connection mechanism
  retryConnection() {
    setTimeout(() => {
      alert('Retrying to join the meeting...');
      this.cleanupJitsi(); // Clean up the existing instance
      this.initJitsi(); // Reinitialize the connection
    }, 5000); // Retry after 5 seconds (adjust as needed)
  }
  cleanupJitsi() {
    if (this.api) {
      this.api.dispose(); // Clean up the iframe and stop the session
      console.log('Jitsi iframe cleaned up');
    }
  }
  toggleParticipantsButton(): void {
    this.api.executeCommand('overwriteConfig', {
      toolbarButtons: ['microphone', 'camera', 'fullscreen', 'hangup'],
    });
  }
  handleClose = () => {
    //this.removeCallData();
    this.eventEmitterService.setCallButton.emit(true);
    this.api.dispose();
  };
  private enableEndCallForAllButton(): void {
    const endCallButton = document.getElementById('end-call-btn');
    if (endCallButton) {
      endCallButton.style.display = 'block';
    }
  }

  //---- Called when I leave the meeting----///
  handleVideoConferenceLeft = () => {
    // console.log('id videoConferenceLeft', this.api._myUserID);
    // console.log('id videoConferenceLeft', this.userJitsiId);
    if (!this.isTeamCalling) this.removeCallData();
    this.api.dispose();
    clearInterval(this.timerInterval);
    this.leaveMeeting.emit(true);
  };

  handleParticipantLeft = async (participant: any) => {
    //alert('handleParticipantLeft');
    console.log('id videoConferenceLeft', participant);
  };

  logParticipants(): void {
    const participants = this.api.getParticipants();
    this.totalParticipants = participants.length;
  }

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  setupPiP() {
    const videoElement = document.querySelector('video');
    this.enterPiP(videoElement);
  }

  async enterPiP(videoElement) {
    if (document.pictureInPictureElement) {
      await document.exitPictureInPicture();
    } else {
      const stream = await this.api.getLocalVideoStream();
      videoElement.srcObject = stream;

      videoElement.style.display = 'block';
      await videoElement.requestPictureInPicture();
    }
  }

  createShortName(name) {
    let fullnameArr = name.split(' ');
    let shortName =
      fullnameArr[0].charAt(0).toUpperCase() +
      fullnameArr[1].charAt(0).toUpperCase();
    return shortName;
  }

  RedirectTo(page: string) {
    let url = '/dashboard/' + page;
  }

  updateCallDuration() {
    //this.callStartTime = Date.now();
    this.timerInterval = setInterval(() => {
      const durationInSeconds = Math.floor(
        (performance.now() - this.callStartTime) / 1000
      );
      const minutes = Math.floor(durationInSeconds / 60);
      const seconds = durationInSeconds % 60;
      this.callDuration = `${this.pad(minutes)}:${this.pad(seconds)}`;

      this.timer.emit(this.callDuration);
    }, 1000);
  }

  pad(value: number) {
    return value < 10 ? '0' + value : value;
  }

  removeCallData() {
    const newmeetingid = localStorage.getItem('started_meeting_id');
    let dataPercentage: saveGroupCallInfo = {
      id: newmeetingid,
      meetingid: this.meetingUrl,
      meetingType: 'end',
      is_groupcall: 1,
      Recieveruserid: localStorage.getItem('selectedTeam'),
    };
    this.audioVideoCallApiService.saveGroupCallInfo(dataPercentage).subscribe(
      (response) => {
        if (response.success) {
          localStorage.removeItem('started_meeting_id');
        }
      },
      (error) => {
        //this.isSubmitFormLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.api.dispose();
  }
}
