import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { UtilsService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-calling-screen',
  templateUrl: './calling-screen.component.html',
  styleUrls: ['./calling-screen.component.scss'],
})
export class CallingScreenComponent implements OnInit {
  @Input() meetingUrl: any = null;
  @Input() callingName: string = null;
  @Input() profileImage: string = null;
  width: string = '100%';
  height: string = '100vh';
  left: string = '0%';
  top: string = '0%';
  isCalling: boolean = true;
  constructor(
    private eventEmitterService: EventEmitterService,
    public UtilsService: UtilsService
  ) {}

  ngOnInit(): void {
   
  }
  
  ngOnChanges() {

  }
  
  leaveMeeting() {
    this.isCalling = false;
	const channelUrl = this.meetingUrl;
	this.eventEmitterService.callLeave.emit({channelUrl});
  }
}
