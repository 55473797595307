import { Injectable, OnInit } from '@angular/core';
import { User } from './user';
declare var JitsiMeetExternalAPI: any;
import { Router } from '@angular/router'; // import router from angular router
import { EventEmitterService } from './event-emitter.service';

@Injectable({
  providedIn: 'root',
})
export class JitsiService {
  public api: any;
  user: User;
  namePrincipalRoom: String;
  options: any;
  domain: string = 'webrtc.nexkul.com/';
  // For Custom Controls
  isAudioMuted = true;
  isVideoMuted = true;
  public participants: Set<string> = new Set();
  constructor(
    private route: Router,
    private eventEmitter: EventEmitterService,
    private router: Router
  ) {
    this.user = new User();
    this.namePrincipalRoom = localStorage.getItem('channel_url');
  }
  // moveRoom(nameRoom: String, isAdmin: Boolean): void {
  //   const myNode = document.getElementById('jitsi-iframe');
  //   myNode.innerHTML = '';

  //   console.log('nameRoom' + nameRoom);
  //   console.log('prejoinPageEnabled:' + (this.user.name != '' ? true : false));

  //   this.options = {
  //     roomName: nameRoom,
  //     width: 900,
  //     height: 570,

  //     configOverwrite: {
  //       // prejoinPageEnabled: this.user.name != '' ? false : true,
  //       prejoinPageEnabled: false,
  //       passwordRequired: false,
  //     },
  //     interfaceConfigOverwrite: {
  //       prejoinPageEnabled: false,
  //       passwordRequired: false,
  //       brandWatermark: false, // Disable Jitsi logo
  //       disableModeratorIndicator: true,
  //       disableBranding: true,
  //       disableInvite: true,
  //     },
  //     parentNode: document.querySelector('#jitsi-iframe'),
  //     userInfo: {
  //       displayName:
  //         localStorage.getItem('current_fname') +
  //         ' ' +
  //         localStorage.getItem('current_lname'),
  //       email: localStorage.getItem('user-email'),
  //       avatarUrl:
  //         'http://media.nexkul.com/org_551/ProfilePicture/user_128198/Thumb/13228adb-620e-41c5-93e7-058753f0d33d.png',
  //     },
  //   };
  //   this.api = null;
  //   this.api = new JitsiMeetExternalAPI(this.domain, this.options);

  //   this.api.addEventListeners({
  //     readyToClose: this.handleClose,
  //     participantLeft: this.handleParticipantLeft,
  //     participantJoined: this.handleParticipantJoined,
  //     videoConferenceJoined: this.handleVideoConferenceJoined,
  //     videoConferenceLeft: this.handleVideoConferenceLeft,
  //     audioMuteStatusChanged: this.handleMuteStatus,
  //     videoMuteStatusChanged: this.handleVideoStatus,
  //     participantRoleChanged: this.participantRoleChanged,
  //     passwordRequired: this.passwordRequired,
  //     endpointTextMessageReceived: this.endpointTextMessageReceived,
  //     videoConferenceEnded: this.conferenceEnded,
  //   });
  //   this.api.executeCommand(
  //     'avatarUrl',
  //     'https://avatars0.githubusercontent.com/u/3671647'
  //   );
  //   this.api.addListener('readyToClose', () => {
  //     this.api.dispose();
  //   });
  //   this.api.addListener('videoConferenceJoined', () => {
  //     this.api.executeCommand('togglePictureInPicture');
  //   });
  //   // this.participants = this.api.getParticipants();
  //   // this.api.addEventListener('participantJoined', (participant) => {
  //   //   this.addParticipant(participant);
  //   // });

  //   // this.api.addEventListener('participantLeft', (participant) => {
  //   //   this.removeParticipant(participant);
  //   // });
  //   this.api.addEventListener('conferenceLeft', () => {
  //     alert('fff');
  //   });
  // }
  conferenceEnded() {
    alert('Conference has ended.');
  }
  changeRouterLink(value: any) {
    this.namePrincipalRoom = value;

    const myNode = document.getElementById('jitsi-iframe');
    myNode.innerHTML = '';

    this.options = {
      roomName: this.namePrincipalRoom,
      width: 700,
      height: 250,
      configOverwrite: {
        prejoinPageEnabled: false,
        openBridgeChannel: 'datachannel',
      },
      interfaceConfigOverwrite: {
        // overwrite interface properties
      },
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: localStorage.getItem('current_fname'),
        email: localStorage.getItem('user-email'),
        avatarURL: localStorage.getItem('cropped-profile-image-pic'),
      },
    };
    this.api = new JitsiMeetExternalAPI(this.domain, this.options);
  }

  handleClose = () => {
    this.api.dispose();
    this.router.navigate(['dashboard/']);
    // // return;
  };
  addParticipant(participant) {
    if (!this.participants.has(participant.getId())) {
      this.participants.add(participant.getId());
      // Update your UI or state as needed
    } else {
    }
  }
  removeParticipant(participant) {
    this.participants.delete(participant.getId());
    // Update your UI or state as needed
  }
  endpointTextMessageReceived = async (event) => {
    // console.log('mensaje recibido');
    // console.log(event);
    // console.log(event.data.eventData.text);
    if ((event.data.eventData.text = 'mover a principal')) {
      //this.moveRoom('grupo 1', true);
    }
  };

  passwordRequired = async () => {
    //console.log('passwordRequired'); // { id: "2baa184e" }
    // this.api.executeCommand('password', 'The Password');
  };

  handleParticipantLeft = async (participant: any) => {
    //console.log('handleParticipantLeft', participant); // { id: "2baa184e" }
    const data = await this.getParticipants();
    this.handleClose();
  };

  participantRoleChanged = async (participant: any) => {
    console.log('participantRoleChanged', participant);
    //if (participant.role === "moderator")
    {
      console.log('participantRoleChanged:', participant.role);
      // this.api.executeCommand('password', 'The Password');
    }
  };

  handleParticipantJoined = async (participant: any) => {
    //console.log('OJOJOJOJ  handleParticipantJoined', participant); // { id: "2baa184e", displayName: "Shanu Verma", formattedDisplayName: "Shanu Verma" }

    const data = await this.getParticipants();
  };

  handleVideoConferenceJoined = async (participant: any) => {
    // console.log('handleVideoConferenceJoined', participant); // { roomName: "bwb-bfqi-vmh", id: "8c35a951", displayName: "Akash Verma", formattedDisplayName: "Akash Verma (me)"}
    /*
    displayName: "userNameTest"
formattedDisplayName: "userNameTest (me)"
id: "19563d97"
roomName: "PrincipalRoom"
*/

    this.user.setName(participant.userNameTest);
    this.namePrincipalRoom = participant.roomName;

    const data = await this.getParticipants();
  };

  handleVideoConferenceLeft = () => {
    this.eventEmitter.jitsiClose.emit(true);
    this.api.dispose();
    this.router.navigate(['dashboard/']);
  };

  handleMuteStatus = (audio: any) => {
    console.log('handleMuteStatus', audio); // { muted: true }
  };

  handleVideoStatus = (video: any) => {
    console.log('handleVideoStatus', video); // { muted: true }
  };

  getParticipants() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.api.getParticipantsInfo()); // get all participants
      }, 500);
    });
  }

  // custom events
  executeCommand(command: string) {
    this.api.executeCommand(command);
    if (command == 'hangup') {
      this.route.navigate(['/thank-you']);
      return;
    }

    if (command == 'toggleAudio') {
      this.isAudioMuted = !this.isAudioMuted;
    }

    if (command == 'toggleVideo') {
      this.isVideoMuted = !this.isVideoMuted;
    }
  }
}
