import { Component, OnInit } from '@angular/core';
import { AuthHttpService } from 'src/app/service/auth-http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/service/store.service';
import { EventEmitterService } from 'src/app/service/event-emitter.service';
import { SendbirdCustomService } from 'src/app/service/sendbird-custom.service';
import { LogoutService } from 'src/app/service/logout.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
pushNotificationToken: string;
disconnectPushNotification: Subscription;

  constructor(
    private sbservice: SendbirdCustomService,
    private lgOut: LogoutService,
	private eventEmitterService: EventEmitterService,
    private http: AuthHttpService,
    private store: StoreService,
    private router: Router,
    private dialogRef: MatDialog
  ) {
	// this.disconnectPushNotification = this.eventEmitterService.disconnectPushNotificationEmitter.subscribe((status) => {
		// alert('inside');
      // this.pushNotificationToken = localStorage.getItem('push-notification-token');
	  // this.disconnectsendBird();
	  // localStorage.removeItem('clear-push-token');
    // });
  }

  ngOnInit(): void {
	this.eventEmitterService.headerEmitter.emit(true);
	const authtoken = localStorage.getItem('authentication-token');
	this.pushNotificationToken = localStorage.getItem('push-notification-token');

    let data = {
      token: authtoken,
    };
	try {
		this.http.logoutTokenExpiry(data).subscribe({
		  next: (response) => {
			console.log(response);
			if (response.code == 200) {
				this.logoutFromAll();
			} 
		  }, error: (err) => {
			this.logoutFromAll();
		  }
		});
	} catch (e) {
        this.logoutFromAll();
    }
  }

  async disconnectsendBird(): Promise<void> {
    try {
	  console.log('this.pushNotificationToken:',this.pushNotificationToken);
      await this.sbservice.unregisterPushNotificationTokenWithSendbird(this.pushNotificationToken);
      const disconnected = await this.sbservice.sbDisconnect();
      if (disconnected) {
        console.log('SendBird disconnected successfully.');
      } else {
        console.log('SendBird disconnection failed.');
      }
    } catch (error) {
      console.error('Error occurred during SendBird disconnection:', error);
      console.log('SendBird disconnection failed.');
    }
  }
  
  async logoutFromAll() {
	if(this.pushNotificationToken != null) {
	this.disconnectsendBird()
	.then((data) => {
		this.store.removeInvitationToken();
		localStorage.clear();
		localStorage.clear();
		this.dialogRef.closeAll();
		//this.router.navigate(['/login']);
		this.redirectToLogin();
      })
      .catch((error) => {
		this.logoutFromAll();
        //console.error('Error loading notifications:', error);
      });
	} else {
		this.store.removeInvitationToken();
		localStorage.clear();
		localStorage.clear();
		this.dialogRef.closeAll();
		this.redirectToLogin();
	}
  }
  
  redirectToLogin() {
  location.href = "/login";
  }
}
