import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpResponse } from '../interfaces/http-response.interface';
import * as moment from 'moment';
import 'moment-timezone';
import {
  saveGroupCallInfo,
  getGroupCallInfo,
} from '../interfaces/audio-video-call';

@Injectable({
  providedIn: 'root',
})
export class AudioVideoCallApiService {
  private endpoint: string = environment.endpoint;
  private endpoint2: string = environment.endpoint2;
  constructor(private httpClient: HttpClient) {}

  saveGroupCallInfo(data: saveGroupCallInfo) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/AddCallDetailsSendPushNotification',
      data
    );
  }
  getGroupCallInfo(data: getGroupCallInfo) {
    return this.httpClient.post<HttpResponse>(
      this.endpoint + 'api/User/GetUserInProcessCallDetails',
      data
    );
  }
}
